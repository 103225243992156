import apiClient from './apiClient';

export const getPaginatedApiCall =
  ({ route }) =>
  async ({ take, page, filter = {} }) => {
    const data = await apiClient.get(route, {
      params: {
        take,
        page,
        ...filter,
      },
    });
    return data;
  };
export const createGetItemById =
  ({ route }) =>
  async (id) => {
    const { data } = await apiClient.get(`${route}/${id}`);
    return data;
  };

export const getFlatListApiCall =
  ({ route }) =>
  async () => {
    const data = await apiClient.get(route);
    return data;
  };

const getApiPutCall = ({ route }) => {
  return async (entityId, entityUpdateData) => {
    return await apiClient.put(`${route}/${entityId}`, entityUpdateData);
  };
};
const getApiPostCall = ({ route }) => {
  return async (createData) => {
    return await apiClient.post(route, createData);
  };
};

const getApiDeleteCall = ({ route }) => {
  return async (entityId) => {
    const data = await apiClient.delete(`${route}/${entityId}`);
    return data;
  };
};

export const getPaginatedRestModule = ({ route }) => {
  const fetchItems = getPaginatedApiCall({
    route,
  });

  const fetchItem = createGetItemById({
    route,
  });
  const updateItem = getApiPutCall({ route });

  const deleteItem = getApiDeleteCall({ route });

  const createItem = getApiPostCall({ route });

  return {
    updateItem,
    createItem,
    deleteItem,
    fetchItem,
    fetchItems,
  };
};
