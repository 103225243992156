import QueryString from 'qs';
import {createRouter, createWebHistory} from 'vue-router';
import HomeView from '../views/HomeView.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView,
            redirect: {
                path: '/orders',
                /*query: {
                    users: {userType: 'candidate'},
                },*/
            },
            children: [
                {
                    path: 'orders',
                    name: 'orders',
                    sensitive: true,
                    component: () =>
                        import(/* webpackChunkName: "auth" */ '../views/OrdersView.vue'),
                },
                {
                    path: 'orders/:id?',
                    name: 'order',
                    alias: ['/orders/create'],
                    props: (route) => ({id: route.params.id, ...route.query}),
                    component: () =>
                        import(/* webpackChunkName: "auth" */ '../views/OrderView.vue'),
                },
                {
                    path: 'invoices',
                    name: 'invoices',
                    sensitive: true,
                    component: () =>
                        import(/* webpackChunkName: "auth" */ '../views/InvoicesView.vue'),
                },
                {
                    path: 'invoices/:id?',
                    name: 'invoice',
                    alias: ['/invoices/create'],
                    props: (route) => ({id: route.params.id, ...route.query}),
                    component: () =>
                        import(/* webpackChunkName: "auth" */ '../views/InvoiceView.vue'),
                },
                {
                    path: 'users',
                    name: 'users',
                    sensitive: true,
                    component: () =>
                        import(/* webpackChunkName: "users" */ '../views/UsersView.vue'),
                },
                {
                    path: 'users/:id?',
                    name: 'user',
                    alias: ['/users/create'],
                    props: (route) => ({id: route.params.id, ...route.query}),
                    component: () =>
                        import(/* webpackChunkName: "user" */ '../views/UserView.vue'),
                },
            ],
        },
        {
            path: '/auth',
            name: 'auth',
            component: () =>
                import(/* webpackChunkName: "auth" */ '../views/AuthView.vue'),
        },
        {
            path: '/logout',
            name: 'logout',
            component: () =>
                import(/* webpackChunkName: "logout" */ '../views/LogoutView.vue'),
        },
    ],
    parseQuery(query) {
        return QueryString.parse(query);
    },
    stringifyQuery(query) {
        const result = QueryString.stringify(query);
        return result;
    },
});

export default router;
