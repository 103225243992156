import apiClient from './apiClient';
import { PUBLIC_API_ROUTES } from '../constants/api.constants';

export const apiLogin = async (username, password) => {
  const { data } = await apiClient.post(PUBLIC_API_ROUTES.login, {
    username,
    password,
  });
  return data.accessToken;
};
