import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const useQueryUpdater = () => {
  const store = useStore();
  const router = useRouter();
  const entireQuery = computed(() => store.state.router.query);

  const clearFilterFromQuery = (dataKey, keyToClear) => {
    const currentQuery = store.getters['router/getQueryByKey'](dataKey);
    // eslint-disable-next-line no-unused-vars
    const { [keyToClear]: a, ...rest } = currentQuery;
    router.push({
      query: {
        ...entireQuery.value,
        [dataKey]: { ...rest },
      },
    });
    return;
  };
  const removePortionByKey = (queryKeyToClear) => {
    const currentQuery = store.state.router.query;
    // eslint-disable-next-line no-unused-vars
    const { [queryKeyToClear]: a, ...rest } = currentQuery;
    router.push({
      query: {
        ...rest,
      },
    });
    return;
  };

  const updateQuery = (dataKey, update) => {
    const currentQuery = store.getters['router/getQueryByKey'](dataKey);
    router.push({
      query: {
        ...entireQuery.value,
        [dataKey]: { ...currentQuery, ...update },
      },
    });
    return;
  };
  const appendToQuery = (queryId, value) => {
    const currentQuery = store.state.router.query;
    router.push({
      query: {
        ...currentQuery,
        [queryId]: value,
      },
    });
    return;
  };

  return {
    clearFilterFromQuery,
    updateQuery,
    removePortionByKey,
    appendToQuery,
  };
};

export default useQueryUpdater;
