<template>
  <p class="text-sm">{{ title }}</p>
    <pv-timeline :value="history">
      <template #content="slotProps">
        <p class="text-sm text-gray-700 p-0 m-0">{{ slotProps.item.matchState }}</p>
        <p class="text-sm text-gray-500 p-0 m-0">{{ formatDate(slotProps.item.created) }}</p>
        <p class="text-sm text-gray-500 p-0 m-0">by {{ slotProps.item.changedStateBy }}</p>
      </template>
    </pv-timeline>
</template>
<script>
import dayjs from 'dayjs';
export default {
  name: 'MatchHistory',
  props: {
    history: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods:{
    formatDate(dateString){
      return dayjs(dateString).format('DD.MM.YYYY, HH:mm');
    }
  }
};
</script>
<style>
.p-timeline-event-opposite{
  display: none !important;
}
</style>
