const userCustomGetters = {
  getAdminRelatedInfo: (state, entityGetters) => {
    return {
      ...entityGetters.getDraftOrRealValues(['rating', 'comment', 'jobRecommendations']),
      ...entityGetters.getDraftOrRealTypedValues([
        'blacklistEmployers',
        'preferredEmployers',
        'tags',
        'agents',
      ]),
    };
  },
  getUserInfo: (state, entityGetters) => {
    return entityGetters.getDraftOrRealValues([
      'firstName',
      'lastName',
      'birthdate',
      'gender',
      'phoneE164',
      'initialInterview',
      'contactTime',
      'email',
      'pauseMonths',
      'id',
      'invitedBy',
      'communicationState',
    ]);
  },
  getJobRelatedInfo: (state, entityGetters) => {
    return {
      ...entityGetters.getDraftOrRealValues([
        'yearsExperience',
        'treatmentType',
        'languageSkillsDE',
        'drivingLicense',
        'educationState',
        'canStartDate',
        'userLocations',
        'wishes',
      ]),
      ...entityGetters.getDraftOrRealTypedValues([
        'categories',
        'workAreas',
        'employmentTypes',
        'positions',
        'tags'
      ]),
    };
  },
};

export default userCustomGetters;
