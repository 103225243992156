import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { loadFonts } from './plugins/webfontloader';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import 'vue-select/dist/vue-select.css';
import PrimeVue from 'primevue/config';
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';
import Toolbar from 'primevue/toolbar';
import TabMenu from 'primevue/tabmenu';
import Divider from 'primevue/divider';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Tooltip from 'primevue/tooltip';
import AutoComplete from 'primevue/autocomplete';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import MultiSelect from 'primevue/multiselect';
import Listbox from 'primevue/listbox';
import InputNumber from 'primevue/inputnumber';
import DropDown from 'primevue/dropdown';
import Editor from 'primevue/editor';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Calendar from 'primevue/calendar';
import Rating from 'primevue/rating';
import Textarea from 'primevue/textarea';
import Panel from 'primevue/panel';
import Image from 'primevue/image';
import ProgressSpinner from 'primevue/progressspinner';
import Sidebar from 'primevue/sidebar';
import FileUpload from 'primevue/fileupload';

import './assets/_theme.scss'; //theme
import 'primevue/resources/primevue.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import '/node_modules/primeflex/primeflex.css';

import vSelect from 'vue-select';
import Menu from "primevue/menu";
import mitt from 'mitt';
import Timeline from "primevue/timeline";
import ProgressBar from "primevue/progressbar";
const emitter = mitt();

loadFonts();
const app = createApp(App).use(router).use(store).use(Toast).use(PrimeVue);
app.component('pf-v-select', vSelect);

app.component('pv-timeline', Timeline)
app.component('pv-card', Card);
app.component('pv-toolbar', Toolbar);
app.component('pv-input-text', InputText);
app.component('pv-input-password', Password);
app.component('pv-button', Button);
app.component('pv-tab-menu', TabMenu);
app.component('pv-menu', Menu);
app.component('pv-divider', Divider);
app.component('pv-table', DataTable);
app.component('pv-column', Column);
app.component('pv-autocomplete', AutoComplete);
app.component('pv-radio-button', RadioButton);
app.component('pv-checkbox', Checkbox);
app.component('pv-multi-select', MultiSelect);
app.component('pv-listbox', Listbox);
app.component('pv-input-number', InputNumber);
app.component('pv-dropdown', DropDown);
app.component('pv-editor', Editor);
app.component('pv-accordion', Accordion);
app.component('pv-accordion-tab', AccordionTab);
app.component('pv-calendar', Calendar);
app.component('pv-rating', Rating);
app.component('pv-text-area', Textarea);
app.component('pv-panel', Panel);
app.component('pv-image', Image);
app.component('pv-progress-spinner', ProgressSpinner);
app.component('pv-sidebar', Sidebar);
app.component('pv-file-upload', FileUpload);
app.component('pv-progress-bar', ProgressBar);

app.directive('tooltip', Tooltip);
app.config.globalProperties.emitter = emitter;
app.mount('#app');
