<template>
  <div>
    <label :for="valueKey" class="text-sm">{{ label }}</label>
    <pv-input-number
      :modelValue="modelValue"
      :mode="mode || undefined"
      :minFractionDigits="minFractionDigits || undefined"
      :maxFractionDigits="maxFractionDigits || undefined"
      :currency="currency || undefined"
      :locale="locale || undefined"
      :suffix="suffix || undefined"
      @input="handleInput"
      :id="valueKey || label.split(' ').join('')"
      class="w-full mt-2"
    />
  </div>
</template>
<script>
export default {
  name: 'PfInputNumber',
  props: {
    valueKey: {
      type: Number,
      default: null,
    },
    minFractionDigits: {
      type: Number,
      default: null,
    },
    maxFractionDigits: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: '',
    },
    currency: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [Number, String],
      default: null,
    },
  },
  setup(props, { emit }) {
    return {
      handleInput(event) {
        emit('update:modelValue', event.value);
      },
    };
  },
};
</script>
