import mapItemsToOptions from '@/utils/mapItemsToOptions';
import { nanoid } from 'nanoid';
import { useToast } from 'vue-toastification';

const fixData = (data) => data.map((x) => ({ ...x, pfId: nanoid() }));

const createPaginatedStateGetters = ({ dataKey }) => {
  return {
    allItems: (state) => state.items,
    itemsAsOptions: ({ items }) =>
      items.map((item) => mapItemsToOptions[dataKey](item)),
    getPagination: (state, getters, rootState, rootGetters) => {
      const { page = 0, take = 50 } =
        rootGetters['router/getQueryByKey'](dataKey) ?? {};
      return { page, take };
    },
    getFilter: (state, getters, rootState, rootGetters) => {
      //eslint-disable-next-line
      const { page, take, ...rest } =
        rootGetters['router/getQueryByKey'](dataKey) ?? {};
      return rest;
    },
    getIsLoading: (state) => state.loading,
  };
};
const toast = useToast();
const createPaginationActions = (fetchCb, deleteCb, updateCb) => {
  return {
    async fetchItems(context) {
      const { take, page } = context.getters.getPagination;
      const filter = context.getters.getFilter;
      context.commit('setLoading', true);
      const { data } = await fetchCb({ take, page, filter });
      context.commit('setLoading', false);
      context.commit('setTotalItems', data.total);
      context.commit('setItems', fixData(data.data));
    },
    async deleteItem(context, target) {
      const currentItems = context.state.items;
      try {
        context.commit(
          'setItems',
          currentItems.filter((item) => item.id !== target.id)
        );
        await deleteCb(target.id);
        context.dispatch('fetchItems');
      } catch (error) {
        toast.error('Something went wrong, check console for details.');
        console.error(error);
        context.commit('setItems', currentItems);
      }
    },
    async updateItem(context, { id, data }) {
      try {
        await updateCb(id, data);
        context.dispatch('fetchItems');
      } catch (error) {
        toast.error('Something went wrong, check console for details.');
        console.error(error);
      }
    },
  };
};

const createPaginatedStateMutations = () => {
  return {
    setItems: (state, payload) => {
      state.items = payload;
    },
    setTotalItems: (state, payload) => {
      state.totalItems = payload;
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    },
  };
};

const createPaginatedState = () => {
  return {
    items: [],
    pagination: {
      page: 0,
      take: 10,
    },
    totalItems: 0,
    loading: false,
  };
};

const createPaginatedStoreModule = ({ fetchCb, deleteCb, updateCb, key }) => {
  return {
    namespaced: true,
    actions: createPaginationActions(fetchCb, deleteCb, updateCb),
    mutations: createPaginatedStateMutations(),
    getters: createPaginatedStateGetters({
      dataKey: key,
    }),
    state: createPaginatedState(),
  };
};

export default createPaginatedStoreModule;
