import { PRIVATE_API_ROUTES } from '@/constants/api.constants';
import { getPaginatedRestModule } from './utils';

const route = PRIVATE_API_ROUTES.contacts;

const {
  fetchItem: fetchContact,
  fetchItems: fetchContactsPaginated,
  updateItem: updateContact,
  createItem: createContact,
  deleteItem: deleteContact,
} = getPaginatedRestModule({ route });

export {
  fetchContact,
  fetchContactsPaginated,
  updateContact,
  createContact,
  deleteContact,
};
