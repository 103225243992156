<template>
  <div>
    <label class="text-sm">{{ label }}</label>
    <pv-autocomplete
      class="w-full mt-2"
      dropdown
      :suggestions="suggestions"
      :modelValue="autocompleteModel"
      @complete="handleQ"
      @item-select="handleSelection"
      @clear="autocompleteModel = ''"
      @item-unselect="handleItemUnselect"
      field="title"
      :delay="500"
      :placeholder="placeholder"
      :multiple="multiple"
    >
      <template #item="slotProps">
        <div>{{ slotProps.item.title }}</div>
      </template>
    </pv-autocomplete>
  </div>
</template>
<script>
import { computed, ref } from '@vue/runtime-core';

export default {
  name: 'PfOptionSelect',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [Array, String],
      default: (rawProps) => {
        return rawProps.multiple ? [] : '';
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const searchString = ref('');
    const suggestions = ref([]);

    const autocompleteModel = computed({
      get() {
        if (searchString.value && !props.multiple) {
          return searchString.value;
        }
        if (props.multiple) {
          return props.items.filter((item) =>
            props.modelValue.includes(String(item.id))
          );
        } else {
          const foundItem = props.items.find(
            (item) => String(props.modelValue) === String(item.id)
          );
          return foundItem ?? '';
        }
      },
      set(e) {
        emit('update:modelValue', e);
      },
    });

    const clearItem = (item) => {
      autocompleteModel.value = props.modelValue.filter((id) => id !== item.id);
    };

    const handleSelection = (e) => {
      if (e.value) {
        searchString.value = '';
        if (!props.multiple) {
          autocompleteModel.value = e.value.id;
        } else {
          const isAlreadySelected =
            props.modelValue.length &&
            props.modelValue.some((id) => id === e.value.id);
          if (isAlreadySelected) {
            clearItem(e.value);
          } else {
            autocompleteModel.value = [
              ...autocompleteModel.value.map(({ id }) => id),
              e.value.id,
            ];
          }
        }
      }
    };

    return {
      suggestions,
      autocompleteModel,
      handleSelection,
      handleQ: (e) => {
        searchString.value = e.query;
        suggestions.value = props.items.filter((val) =>
          val.title.toLowerCase().includes(e.query.toLowerCase())
        );
      },
      handleClear: () => {
        autocompleteModel.value = '';
      },
      findItem(selectedItem) {
        return props.items.find((item) => item.id === selectedItem.id)?.title;
      },
      handleItemUnselect: (e) => {
        clearItem(e.value);
      },
    };
  },
};
</script>
