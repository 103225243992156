<template>
  <router-view />
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  name: 'App',
  computed: {
    ...mapState('auth', ['token']),
    routeQuery() {
      return this.$route.query;
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapMutations('router', { setRouteState: 'setRoute' }),
  },
  watch: {
    routeQuery(val) {
      this.setRouteState(val);
    },
  },
};
</script>
<style lang="css">
.max-lines-2{
  max-width: 350px;
}

.max-lines-2 span{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}


.badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.badge-blue{
  background-color: #b3e5fc;
  color: #23547b;
}

.badge-purple {
  background-color: #eccfff;
  color: #694382;
}

.badge-green {
  background-color: #c8e6c9;
  color: #256029;
}

.badge-orange {
  background-color: #ffd8b2;
  color: #805b36;
}

.badge-red {
  background-color: #ffcdd2;
  color: #c63737;
}

.badge-grey {
  background-color: #eaeaea;
  color: black;
}

.badge-yellow {
  background-color: #fff9c6;
  color: #e2c314;
}

</style>
