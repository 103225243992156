const orderStates = [
  { id: 'pending', title: 'pending' },
  { id: 'approved', title: 'approved' },
  { id: 'rejected', title: 'rejected (email)' },
  { id: 'inProgress', title: 'inProgress' },
  { id: 'shipped', title: 'shipped (email)' },
  { id: 'cancelled', title: 'cancelled' },
  { id: 'refunded', title: 'refunded' },
  { id: 'onHold', title: 'onHold' },
  { id: 'completed', title: 'completed (email)' },
];

export default orderStates;
