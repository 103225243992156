<template>
  <pv-confirm-dialog></pv-confirm-dialog>
  <form @submit.prevent="handleSubmit">
    <div class="grid pt-3">
      <pf-option-select class="col-12" :items="taskStatesUpdate" v-model="taskState" label="Task state"/>

      <pf-input-text-area
          class="col-12"
          rows="3"
          :queryParent="queryParent"
          v-model="title"
          label="Title"
      />

      <pf-input-date
          class="col-8"
          :label="'Datum'"
          v-model="dueUntil"
      />

      <pf-input-time
          class="col-4"
          :label="'Uhrzeit'"
          v-model="dueUntilTime"
      />

      <div class="col-12">
        <pf-select-restful
            class="flex-1"
            v-model="userId"
            :restKey="'users'"
            :initialValueOptions="user"
            label="User"
        />
        <a :href="userUrl"> <i class="pi pi-link text-blue-500"/></a>
      </div>

      <div class="col-12 flex justify-content-end mt-5">
        <pv-button type="submit" label="SAVE"/>
      </div>
    </div>
  </form>
</template>
<script>
import {reactive, ref, toRefs} from '@vue/reactivity';
import {useToast} from 'vue-toastification';
import useQueryUpdater from '@/hooks/useQueryUpdater';
import {onMounted, watch} from '@vue/runtime-core';
import {useStore} from 'vuex';
import {fetchUser} from '@/api/api.users';
import {fetchOrder} from '@/api/api.orders';
import PfSelectRestful from '@/components/Input/PfSelectRestful.vue';
import PfOptionSelect from '@/components/Input/PfOptionSelect.vue';
import {fetchEmployer} from '@/api/api.employers';
import PfInputDate from "@/components/Input/PfInputDate";
import PfInputTextArea from "@/components/Input/PfInputTextArea";
import taskStates from "@/constants/taskStates.constants";
import {createTask, fetchTask, updateTask} from "@/api/api.tasks";
import PfInputTime from "@/components/Input/PfInputTime";
import dayjs from "dayjs";

export default {
  name: 'CreateTask',
  props: {
    queryParent: {
      type: String,
      default: null,
    },
  },
  components: {PfSelectRestful, PfOptionSelect, PfInputDate, PfInputTextArea, PfInputTime},
  setup(props) {
    const formData = reactive({
      taskState: 'open',
      jobId: null,
      employerId: null,
      userId: null,
      title: '',
      dueUntil: null,
      dueUntilTime: null,
    });
    const existingTaskId = ref();
    const userUrl = ref();
    const jobUrl = ref();
    const initialData = reactive({
      job: null,
      employer: null,
      user: null,
    });
    watch(
        () => formData.jobId,
        async (currVal, prevVal) => {
          if (currVal !== prevVal) {
            if (currVal) {
              const {employer} = await fetchOrder(currVal);
              initialData.employer = employer;
              formData.employerId = employer.id;
            }
          }
        }
    );

    watch(
        () => formData.dueUntilTime,
        (dateTime) => {
          if(formData.dueUntil){
            formData.dueUntil = dayjs(formData.dueUntil).hour(dayjs(dateTime).hour()).minute(dayjs(dateTime).minute());
          }else{
            formData.dueUntil = dayjs().hour(dayjs(dateTime).hour()).minute(dayjs(dateTime).minute());
          }
        },
    );

    const {removePortionByKey} = useQueryUpdater();
    const toast = useToast();
    const store = useStore();

    onMounted(async () => {
      const entireQuery = store.state.router.query;
      const relevantUrlQuery = props.queryParent
          ? entireQuery[props.queryParent]
          : entireQuery;

      if (relevantUrlQuery) {
        const {taskId, jobId, employerId, userId} = relevantUrlQuery;
        if (taskId) {
          console.log("!!!!!!!!!!!!!!!!!!!!!")
          const task = await fetchTask(taskId);
          userUrl.value = `${window.location.protocol}//${window.location.host}/users/${task.userId}`;
          jobUrl.value = `${window.location.protocol}//${window.location.host}/jobs/${task.jobId}`;
          const {
            title,
            taskState,
            user,
            job,
            employer,
            dueUntil,
          } = task;
          existingTaskId.value = taskId;
          Object.assign(formData, {
            title,
            taskState,
            dueUntil,
            dueUntilTime: dueUntil,
            jobId: job?.id,
            employerId: employer?.id,
            userId: user?.id,
          });
          if (user) {
            initialData.user = user;
          }
          if (job) {
            initialData.job = job;
          }
          if (employer) {
            initialData.employer = employer;
          }
        }
        if (jobId) {
          const job = await fetchOrder(jobId);
          initialData.job = job;
          formData.jobId = job.id;
        }
        if (employerId) {
          const employer = await fetchEmployer(employerId);
          initialData.employer = employer;
          formData.employerId = employer.id;
        }
        if (userId) {
          const user = await fetchUser(userId);
          initialData.user = user;
          formData.userId = user.id;
        }
      }
    });
    const handleSubmit = async () => {
      try {
        const {
          title,
          taskState,
          userId,
          jobId,
          employerId,
          dueUntil,
        } = formData;
        const payload = {
          title,
          taskState,
          userId,
          jobId,
          employerId,
          dueUntil
        };
        if (existingTaskId.value) {
          await updateTask(existingTaskId.value, payload);
        } else {
          await createTask(payload);
        }
        store.dispatch('tasks/fetchItems');
        toast.success('Good job!');
        removePortionByKey('modal');
      } catch (error) {
        console.log(error)
        toast.error('Something went terribly wrong. Check console for details');
      }
    };
    return {
      ...toRefs(formData),
      taskStatesUpdate: taskStates,
      handleSubmit,
      existingTaskId: existingTaskId,
      userUrl,
      jobUrl,
      ...toRefs(initialData),
    };
  },
};
</script>
