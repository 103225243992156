import { PRIVATE_API_ROUTES } from '@/constants/api.constants';
import { getPaginatedRestModule } from './utils';
import apiClient from "@/api/apiClient";

export const getUserFilePreviewUrl = async (id) => {
  const { data } = await apiClient.get(`${PRIVATE_API_ROUTES.getUserFilePreviewUrl}/${id}`);
  return data;
};

export const uploadUserFiles = async (userId, files) => {
  const formData = new FormData();
  files.forEach(file=>{
    formData.append("files", file);
  });
  const headers = {
    "Content-Type": "multipart/form-data"
  }
  const { data } = await apiClient.post(`${PRIVATE_API_ROUTES.users}/${userId}/files`, formData, {
    headers: headers
  });
  return data;
};

const route = PRIVATE_API_ROUTES.users;

const {
  fetchItem: fetchUser,
  fetchItems: fetchUsersPaginated,
  updateItem: updateUser,
  createItem: createUser,
  deleteItem: deleteUser,
} = getPaginatedRestModule({ route });

export { fetchUser, fetchUsersPaginated, updateUser, createUser, deleteUser };
