import { PRIVATE_API_ROUTES } from '@/constants/api.constants';
import { getPaginatedRestModule } from './utils';
import apiClient from "@/api/apiClient";

const route = PRIVATE_API_ROUTES.employers;

export const uploadEmployer = async (employerId, files) => {
  const formData = new FormData();
  files.forEach(file=>{
    formData.append("file", file);
  });
  const headers = {
    "Content-Type": "multipart/form-data"
  }
  const { data } = await apiClient.post(`${PRIVATE_API_ROUTES.employers}/${employerId}/logo`, formData, {
    headers: headers
  });
  return data;
};

const {
  fetchItem: fetchEmployer,
  fetchItems: fetchEmployersPaginated,
  updateItem: updateEmployer,
  createItem: createEmployer,
  deleteItem: deleteEmployer,
} = getPaginatedRestModule({ route });

export {
  fetchEmployer,
  fetchEmployersPaginated,
  updateEmployer,
  createEmployer,
  deleteEmployer,
};
