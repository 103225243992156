<template>
  <pv-toolbar class="bg-white p-0 overflow-x-scroll">
    <template #start>
      <router-link to="/" v-slot="{ href, navigate }" custom>
        <pv-button class="p-button-text" :href="href" @click="navigate">
          <img alt="logo" src="../assets/logo.svg" height="25" class="mr-2" >
        </pv-button>
      </router-link>
    </template>
    <template #end>
      <pf-data-links />
      <PfSettings/>
    </template>
  </pv-toolbar>
  <pf-root-modal />
  <router-view />
</template>

<script>
import PfDataLinks from '@/components/Navbar/PfDataLinks.vue';
import PfRootModal from '@/components/PfRootModal.vue';
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';
import PfSettings from "@/components/Navbar/PfSettings";

export default defineComponent({
  components: {PfSettings, PfDataLinks, PfRootModal },
  name: 'HomeView',
  data() {
    return {
      panel: [],
    };
  },
  computed: {
    ...mapState('auth', ['token']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
  },
  beforeMount() {
    if (!this.token) {
      this.$router.replace({ name: 'auth' });
    }
  },
});
</script>
<style>
a:focus {
  box-shadow: none !important;
}

.container {
  max-width: 752px;
  margin: auto;
}

.google-autocomplete .p-inputtext {
  border: none !important;
}
</style>
