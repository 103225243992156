
export const API_BASE_URL = process.env.VUE_APP_BASE_URL;

export const PUBLIC_API_ROUTES = {
  login: '/auth/login',
  shareMatch: '/share/match',
  matchAccept: '/share/matchAccept',
  startConversation: '/share/startConversation',
  matchDecline: '/share/matchDecline',
  matchAddSalary: 'share/matchAddSalary'
};

export const PRIVATE_API_ROUTES = {
  orders: '/orders',
  pages: '/pages',
  invoices: '/invoices',
  employers: '/employers',
  employerGroups: '/employerGroups',
  positions: '/positions',
  educationTitles: '/educationTitles',
  furtherEducationTitles: '/furtherEducationTitles',
  workAreas: '/workAreas',
  tags: '/tags',
  employmentTypes: '/employmentTypes',
  categories: '/categories',
  contacts: '/contacts',
  jobBoosts: '/jobBoosts',
  matches: '/matches',
  tasks: '/tasks',
  calls: '/calls',
  users: '/users',
  shareMatchPreview: '/share/matchPreview',
  sendNotReached: '/communications/sendNotReached',
  matchWorkflows: 'matchWorkflows',
  getPreviewUrl: 'invoices/preview',
  getUserFilePreviewUrl: 'users/files',
  sendInvoice: 'invoices/sendInvoice',
  cancelInvoice: 'invoices/cancelInvoice',
};
