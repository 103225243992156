import { PRIVATE_API_ROUTES } from '@/constants/api.constants';
import { getPaginatedRestModule } from './utils';

const route = PRIVATE_API_ROUTES.matches;

const {
  fetchItem: fetchMatch,
  fetchItems: fetchMatchesPaginated,
  updateItem: updateMatch,
  createItem: createMatch,
  deleteItem: deleteMatch,
} = getPaginatedRestModule({ route });

export {
  fetchMatch,
  fetchMatchesPaginated,
  updateMatch,
  createMatch,
  deleteMatch,
};
