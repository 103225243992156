const mapContactsToOptions = (item) => {
  return {
    id: item.id,
    title: `${item.firstName} ${item.lastName}`,
  };
};

const mapUsersToOptions = (item) => {
  return {
    id: item.id,
    title: `${item.firstName} ${item.lastName}`,
  };
};
const mapEmployersToOptions = ({ id, name }) => {
  return {
    id,
    title: name,
  };
};

const mapEmployerGroupsToOptions = ({ id, name }) => {
  return {
    id,
    title: name,
  };
};

const mapFeesToOptions = (item) => {
  return {
    id: item.id,
    title: `${item.position.title} ${item.fixedFee ? item.fixedFee + " EUR" : ""}${item.percentageFee ? item.percentageFee + " %" : ""}`,
  };
};

const MAP_ITEMS_TO_OPTIONS_DICT = {
  users: mapUsersToOptions,
  contacts: mapContactsToOptions,
  fees: mapFeesToOptions,
  employers: mapEmployersToOptions,
  employerGroups: mapEmployerGroupsToOptions,
};

export default new Proxy(MAP_ITEMS_TO_OPTIONS_DICT, {
  get: (target, prop) => {
    if (target[prop]) {
      return target[prop];
    } else {
      return (item) => ({ id: item.id, title: item.title });
    }
  },
});
