import { flatListCategories } from '@/api/api.categories';
import {
  createContact,
  deleteContact,
  fetchContact,
  fetchContactsPaginated,
  updateContact,
} from '@/api/api.contacts';
import {
  createEmployer,
  deleteEmployer,
  fetchEmployer,
  fetchEmployersPaginated,
  updateEmployer,
} from '@/api/api.employers';
import { flatListEmploymentTypes } from '@/api/api.employmentTypes';
import {
  createOrder,
  deleteOrder,
  fetchOrder,
  fetchOrdersPaginated,
  updateOrder,
} from '@/api/api.orders';
import {
  deleteMatch,
  fetchMatchesPaginated,
  updateMatch,
} from '@/api/api.matches';
import { flatListPositions } from '@/api/api.positions';
import {
  createUser,
  deleteUser,
  fetchUser,
  fetchUsersPaginated,
  updateUser,
} from '@/api/api.users';
import { flatListWorkAreas } from '@/api/api.workAreas';
import { createStore } from 'vuex';
import authModule from './authModule';
import routerModule from './routerModule';
import createFlatListStoreModule from './utils/createFlatListStoreModule';
import createPaginatedStoreModule from './utils/createPaginatedStoreModule';
import {
  deleteEmployerGroup,
  fetchEmployerGroupsPaginated,
  updateEmployerGroup,
} from '@/api/api.employerGroups';
import createEntityStoreModule from './utils/createEntityStoreModule';
import userCustomGetters from './customGetters/user.getters';
import { flatListTags } from '@/api/api.tags';
import { flatListEducationTitles } from '@/api/api.educationTitles';
import { flatListFurtherEducationTitles } from '@/api/api.furtherEducationTitles';
import {deleteJobBoost, fetchJobBoostsPaginated, updateJobBoost} from "@/api/api.jobBoosts";
import {deleteTask, fetchTasksPaginated, updateTask} from "@/api/api.tasks";
import {createInvoice, deleteInvoice, fetchInvoice, fetchInvoicesPaginated, updateInvoice} from "@/api/api.invoices";
import {createPage, deletePage, fetchPage, fetchPagesPaginated, updatePage} from "@/api/api.pages";
import {createCall, deleteCall, fetchCall, fetchCallsPaginated, updateCall} from "@/api/api.calls";

const paginatedModules = [
  {
    key: 'orders',
    fetchCb: fetchOrdersPaginated,
    deleteCb: deleteOrder,
    updateCb: updateOrder,
  },
  {
    key: 'calls',
    fetchCb: fetchCallsPaginated,
    deleteCb: deleteCall,
    updateCb: updateCall,
  },
  {
    key: 'pages',
    fetchCb: fetchPagesPaginated,
    deleteCb: deletePage,
    updateCb: updatePage,
  },
  {
    key: 'invoices',
    fetchCb: fetchInvoicesPaginated,
    deleteCb: deleteInvoice,
    updateCb: updateInvoice,
  },
  {
    key: 'jobBoosts',
    fetchCb: fetchJobBoostsPaginated,
    deleteCb: deleteJobBoost,
    updateCb: updateJobBoost,
  },
  {
    key: 'employers',
    fetchCb: fetchEmployersPaginated,
    deleteCb: deleteEmployer,
    updateCb: updateEmployer,
  },
  {
    key: 'employerGroups',
    fetchCb: fetchEmployerGroupsPaginated,
    deleteCb: deleteEmployerGroup,
    updateCb: updateEmployerGroup,
  },
  {
    key: 'contacts',
    fetchCb: fetchContactsPaginated,
    deleteCb: deleteContact,
    updateCb: updateContact,
  },
  {
    key: 'matches',
    fetchCb: fetchMatchesPaginated,
    deleteCb: deleteMatch,
    updateCb: updateMatch,
  },
  {
    key: 'tasks',
    fetchCb: fetchTasksPaginated,
    deleteCb: deleteTask,
    updateCb: updateTask,
  },
  {
    key: 'users',
    fetchCb: fetchUsersPaginated,
    deleteCb: deleteUser,
    updateCb: updateUser,
  },
];

const entityModules = [
  {
    key: 'user',
    fetchCb: fetchUser,
    updateCb: updateUser,
    deleteCb: deleteUser,
    customGetters: userCustomGetters,
    createCb: createUser,
  },
  {
    key: 'contact',
    fetchCb: fetchContact,
    updateCb: updateContact,
    deleteCb: deleteContact,
    createCb: createContact,
  },
  {
    key: 'employer',
    fetchCb: fetchEmployer,
    updateCb: updateEmployer,
    deleteCb: deleteEmployer,
    createCb: createEmployer,
  },
  {
    key: 'order',
    fetchCb: fetchOrder,
    updateCb: updateOrder,
    deleteCb: deleteOrder,
    createCb: createOrder,
  },
  {
    key: 'page',
    fetchCb: fetchPage,
    updateCb: updatePage,
    deleteCb: deletePage,
    createCb: createPage,
  },
  {
    key: 'call',
    fetchCb: fetchCall,
    updateCb: updateCall,
    deleteCb: deleteCall,
    createCb: createCall,
  },
  {
    key: 'invoice',
    fetchCb: fetchInvoice,
    updateCb: updateInvoice,
    deleteCb: deleteInvoice,
    createCb: createInvoice,
  },
];

const flatListedModules = [
  { key: 'positions', fetchCb: flatListPositions },
  { key: 'workAreas', fetchCb: flatListWorkAreas },
  { key: 'categories', fetchCb: flatListCategories },
  { key: 'employmentTypes', fetchCb: flatListEmploymentTypes },
  { key: 'tags', fetchCb: flatListTags },
  { key: 'educationTitles', fetchCb: flatListEducationTitles },
  { key: 'furtherEducationTitles', fetchCb: flatListFurtherEducationTitles },
];

const modules = {
  ...authModule,
  ...routerModule,
};

flatListedModules.forEach(({ key, fetchCb }) => {
  Object.assign(modules, {
    [key]: createFlatListStoreModule({
      fetchCb,
      dataKey: key,
    }),
  });
});

paginatedModules.forEach(({ key, fetchCb, deleteCb, updateCb }) => {
  Object.assign(modules, {
    [key]: createPaginatedStoreModule({
      fetchCb,
      deleteCb,
      updateCb,
      key,
    }),
  });
});
entityModules.forEach(
  ({ key, fetchCb, deleteCb, updateCb, customGetters, createCb }) => {
    Object.assign(modules, {
      [key]: createEntityStoreModule({
        fetchCb,
        deleteCb,
        updateCb,
        key,
        customGetters,
        createCb,
      }),
    });
  }
);

export default createStore({
  modules: {
    ...modules,
  },
});
