import apiClient from './apiClient';
import {PRIVATE_API_ROUTES} from "@/constants/api.constants";
import {getPaginatedRestModule} from "@/api/utils";

export const getInvoicePreviewUrl = async (id) => {
  const { data } = await apiClient.get(`${PRIVATE_API_ROUTES.getPreviewUrl}/${id}`);
  return data;
};

export const sendInvoice = async (id) => {
  const { data } = await apiClient.post(`${PRIVATE_API_ROUTES.sendInvoice}/${id}`);
  return data;
};

export const cancelInvoice = async (id, refundPercent) => {
  const { data } = await apiClient.post(`${PRIVATE_API_ROUTES.cancelInvoice}/${id}`, { refundPercent: refundPercent });
  return data;
};

const route = PRIVATE_API_ROUTES.invoices;
const {
  fetchItem: fetchInvoice,
  fetchItems: fetchInvoicesPaginated,
  updateItem: updateInvoice,
  createItem: createInvoice,
  deleteItem: deleteInvoice,
} = getPaginatedRestModule({ route });

export { fetchInvoice, fetchInvoicesPaginated, updateInvoice, createInvoice, deleteInvoice };