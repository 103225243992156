import { PRIVATE_API_ROUTES } from '@/constants/api.constants';
import { getPaginatedRestModule } from './utils';

const route = PRIVATE_API_ROUTES.employerGroups;

const {
  fetchItem: fetchEmployerGroup,
  fetchItems: fetchEmployerGroupsPaginated,
  updateItem: updateEmployerGroup,
  createItem: createEmployerGroup,
  deleteItem: deleteEmployerGroup,
} = getPaginatedRestModule({ route });

export {
  fetchEmployerGroup,
  fetchEmployerGroupsPaginated,
  updateEmployerGroup,
  createEmployerGroup,
  deleteEmployerGroup,
};
