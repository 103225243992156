export default {
  router: {
    namespaced: true,
    getters: {
      getQueryByKey: (state) => (key) => {
        return state.query[key] ?? null;
      },
      getQueryFilterByKey: (state) => (key) => {
        return state.query[key]?.filter ?? null;
      },
      getModalProps: (state) => {
        return state.query.modal;
      },
    },
    state: {
      query: {},
    },
    mutations: {
      setRoute(state, payload) {
        state.query = payload;
      },
    },
  },
};
