<template>
  <pv-tab-menu :model="items" />
</template>

<script>
export default {
  name: 'PfDataLinks',
  setup() {
    const items = [
      {
        label: 'Orders',
        icon: 'pi pi-fw pi-shopping-cart',
        to: {
          path: '/orders',
          query: {
            users: { orderState: 'pending' },
          },
        },
        style: 'box-shadow: none',
      },
    ];
    return { items };
  },
};
</script>
<style>
.p-tabmenuitem{
  font-size: 14px !important;
}
</style>
