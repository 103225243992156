import { PRIVATE_API_ROUTES } from '@/constants/api.constants';
import { getPaginatedRestModule } from './utils';

const route = PRIVATE_API_ROUTES.orders;
const {
  fetchItem: fetchOrder,
  fetchItems: fetchOrdersPaginated,
  updateItem: updateOrder,
  createItem: createOrder,
  deleteItem: deleteOrder,
} = getPaginatedRestModule({ route });

export { fetchOrder, fetchOrdersPaginated, updateOrder, createOrder, deleteOrder };
