<template>
  <div>
    <label class="text-sm">{{ label }}</label>
    <pv-autocomplete
      class="w-full mt-2"
      dropdown
      :disabled="disabled"
      :suggestions="suggestions"
      :modelValue="autocompleteModel"
      @complete="handleQuery"
      @item-select="handleSelection"
      @clear="autocompleteModel = ''"
      @item-unselect="clearItem($event.value)"
      field="title"
      :delay="500"
      :placeholder="placeholder"
      :multiple="multiple"
    >
      <template #item="slotProps">
        <div>
          {{ slotProps.item.title }}
        </div>
      </template>
    </pv-autocomplete>
  </div>
</template>
<script>
import { computed, ref, watch } from '@vue/runtime-core';
import { getPaginatedApiCall } from '@/api/utils';
import { PRIVATE_API_ROUTES } from '@/constants/api.constants';
import mapItemsToOptions from '@/utils/mapItemsToOptions';

const getFetchOptions = (restKey, additionalParams = {}) => {
  const apiCall = getPaginatedApiCall({
    route: PRIVATE_API_ROUTES[restKey],
  });

  const fetchOptions = async (search) => {
    try {
      const { data } = await apiCall({
        take: 200,
        page: 0,
        filter: { search, ...additionalParams },
      });
      const { data: items } = data;
      return items;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    apiCall,
    fetchOptions,
  };
};

export default {
  name: 'PfSelectRestful',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [Array, String],
      default: (rawProps) => {
        return rawProps.multiple ? [] : '';
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    restKey: {
      type: String,
      required: true,
    },
    initialValueOptions: {
      type: [Array, Object],
      default: () => [],
    },
    queryParams: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { fetchOptions } = getFetchOptions(props.restKey, props.queryParams);
    const searchString = ref('');
    const suggestions = ref([]);
    const selectedItems = ref([]);
    const transformFn = mapItemsToOptions[props.restKey];
    watch(
      () => props.initialValueOptions,
      (val) => {
        if (val) {
          if (Array.isArray(val) && val.length) {
            const filteredSelection = selectedItems.value.filter(
              ({ id }) => !val.some((item) => item.id === id)
            );
            selectedItems.value = [
              ...filteredSelection,
              ...val.map((item) => transformFn(item)),
            ];
          } else {
            const filteredSelection = selectedItems.value.filter(
              ({ id }) => val.id !== id
            );
            selectedItems.value = [...filteredSelection, transformFn(val)];
          }
        }
      },
      { immediate: true }
    );

    const autocompleteModel = computed({
      get() {
        if (searchString.value && !props.multiple) return searchString.value;

        if (!props.modelValue) return '';

        return props.multiple
          ? selectedItems.value.filter((item) =>
              props.modelValue.includes(String(item.id))
            )
          : selectedItems.value.find(
              (item) => String(props.modelValue) === String(item.id)
            ) ?? '';
      },
      set(e) {
        emit('update:modelValue', e);
      },
    });

    const clearItem = (item) => {
      selectedItems.value = selectedItems.value.filter(
        ({ id }) => id !== item.id
      );
      autocompleteModel.value = props.modelValue.filter((id) => id !== item.id);
    };

    const handleSelection = (e) => {
      if (e.value) {
        searchString.value = '';
        if (!props.multiple) {
          autocompleteModel.value = e.value.id;
          selectedItems.value = [e.value];
        } else {
          const isAlreadySelected =
            props.modelValue.length &&
            props.modelValue.some((id) => id === e.value.id);
          if (isAlreadySelected) {
            clearItem(e.value);
          } else {
            selectedItems.value = [...selectedItems.value, e.value];
            autocompleteModel.value = [...props.modelValue, e.value.id];
          }
        }
      }
    };
    const handleQuery = async (e) => {
      searchString.value = e.query;
      const items = await fetchOptions(e.query);
      console.log(items);
      suggestions.value = items.map((item) => transformFn(item));
    };

    return {
      suggestions,
      autocompleteModel,
      handleSelection,
      handleQuery,
      clearItem,
    };
  },
};
</script>
