<template>
  <pv-confirm-dialog></pv-confirm-dialog>
  <form @submit.prevent="handleSubmit">
    <div class="flex">
      <div class="flex-1 grid pt-3">
        <pf-option-select class="col-12"
                          :items="existingMatchId ? matchStatesUpdate : matchStatesUpdate.filter(item => item.id === 'pending' || item.id === 'accepted')"
                          v-model="matchState" label="Match state"/>


        <pf-option-select
            v-if="matchState === 'billable' || matchState === 'billed' || matchState === 'paid'"
            class="col-12"
            :label="`Fee (Job position: ${job?.position?.title})`"
            :items="feesOptions"
            v-model="feeId"
        />

        <pf-input-date
            v-if="matchState === 'billed' || matchState === 'paid'"
            class="col-6"
            :label="'Rechnung gestellt am'"
            v-model="billedDate"
        />

        <pf-input-date
            v-if="matchState === 'billed' || matchState === 'paid'"
            class="col-6"
            :label="'Rechnung bezahlt am'"
            v-model="paidDate"
        />

        <pf-input-date
            v-if="matchState === 'hired' || matchState === 'billable' || matchState === 'billed' || matchState === 'paid'"
            class="col-4"
            :label="'Arbeitsbeginn'"
            v-model="workStartDate"
        />
        <pf-input-number
            v-if="matchState === 'hired' || matchState === 'billable' || matchState === 'billed' || matchState === 'paid'"
            class="col-4"
            mode="decimal"
            min-fraction-digits="2"
            max-fraction-digits="2"
            :label="'Jahresbruttogehalt inkl. Zulagen (laut Bewerber)'"
            v-model="salaryByCandidate"
        />

        <pf-input-number
            v-if="matchState === 'hired' || matchState === 'billable' || matchState === 'billed' || matchState === 'paid'"
            class="col-4"
            mode="decimal"
            min-fraction-digits="2"
            max-fraction-digits="2"
            :label="'Jahresbruttogehalt inkl. Zulagen (laut Kunde)'"
            v-model="salaryByCustomer"
        />
        <pf-input-date
            v-if="matchState === 'conversationAccepted' || matchState === 'conversationStarted'"
            class="col-12"
            :label="'Interviewtermin beim Arbeitgeber'"
            v-model="interviewDate"
        />

        <div class="col-12">
          <pf-select-restful
              class="flex-1"
              v-model="userId"
              :restKey="'users'"
              :initialValueOptions="user"
              :label="`User (${user?.email})`"
          />
          <a :href="userUrl"> <i class="pi pi-link text-blue-500"/></a>
        </div>
        <div class="col-12">
          <pf-select-restful
              class="flex-1"
              v-model="jobId"
              :disabled="initialApplication"
              :initialValueOptions="job"
              :restKey="'jobs'"
              :label="`Job (${job?.contactPerson?.prefixDegree} ${job?.contactPerson?.lastName} ${job?.contactPerson?.suffixDegree}, ${job?.contactPerson?.email}, ${job?.contactPerson?.phone}, ${job?.contactPerson?.mobile})`"
          />
          <a :href="jobUrl"> <i class="pi pi-link text-blue-500"/></a>
        </div>
        <div class="col-12">
          <pv-checkbox
              v-model="initialApplication"
              :id="initialApplication"
              binary
          />
          <label for="initialApplication" class="pl-2">Initial application (no specific job)?</label>
        </div>

        <pf-select-restful
            class="col-12"
            v-model="employerId"
            :disabled="!initialApplication"
            :initialValueOptions="employer"
            :restKey="'employers'"
            label="Employer"
        />
        <p class="col-12 m-0 text-sm">Kommentar am User:</p>
        <p class="col-12 m-0 pt-0">{{ commentOnUserEntity }}</p>
        <pf-input-text-area
            class="col-12"
            rows="3"
            :queryParent="queryParent"
            v-model="comment"
            label="Comment by Agents"
        />

        <pf-input-text-area
            class="col-12"
            rows="3"
            :queryParent="queryParent"
            v-model="customerComment"
            label="Comment by Customer"
        />

        <pf-option-select class="col-12" :items="sharePermissions" v-model="sharePermission" label="Share Permission"/>

        <pf-input-text
            v-if="sharePermission !== 'disabled'"
            class="col-12"
            :queryParent="queryParent"
            v-model="shareUrl"
            label="Share Url"
        />

        <div class="grid" v-if="matchState === 'hired' && (!workStartDate || !salaryByCustomer)">
          <p class="col-12 text-orange-500 mt-3">Da der Arbeitsbeginn und/oder Jahresbruttogehalt inkl. Zulagen (laut
            Kunde) nicht angegeben wurde, wird der Kunde eine einmalige E-Mail mit der Aufforderung zur
            Vervollständigung erhalten. Sollten die Informationen bereits vorliegen, bitte direkt oben eintragen.</p>
        </div>
        <div class="grid" v-if="matchState === 'billed'">
          <p class="col-12 text-orange-500 mt-3">Die Rechnung wir automatisch erstellt aber noch nicht an den Kunden gesendet. Um die Rechnung zu versenden klicke oben "Send Invoice". Über das Auge-Symbol kannst du dir die Rechnung nochmals ansehen.</p>
        </div>
        <div class="col-12 align-items-center flex" v-if="matchState === 'conversation'">
          <pv-checkbox
              v-model="sendMailToCustomer"
              id="sendMailToCustomer"
              binary
              class="mr-2"
          />
          <label for="sendMailToCustomer" class="mr-2 text-orange-500">Automatische E-Mail mit dem Profil an den Kunden
            senden?</label>
        </div>
        <div class="col-12 flex justify-content-end mt-5">
          <pv-button type="submit" label="SAVE"/>
        </div>
      </div>
      <div class="pl-3">
        <pv-button icon="pi pi-eye" class="mr-2" @click="openInvoicePreviewUrl" v-if="matchState === 'billed' || matchState === 'paid'"/>
        <pv-button icon="pi pi-send" label="Send Invoice" class="mr-2" @click="sendInvoiceToCustomer" v-if="matchState === 'billed' || matchState === 'paid'"/>
        <MatchHistory :history="history" title="Match History"/>
      </div>
    </div>
  </form>
</template>
<script>
import {reactive, ref, toRefs} from '@vue/reactivity';
import orderStates from '@/constants/orderStates.constants';
import sharePermissions from '@/constants/sharePermission.constants';
import PfInputText from '@/components/Input/PfInputText.vue';
import {createMatch, fetchMatch, updateMatch} from '@/api/api.matches';
import {useToast} from 'vue-toastification';
import useQueryUpdater from '@/hooks/useQueryUpdater';
import {onMounted, watch} from '@vue/runtime-core';
import {useStore} from 'vuex';
import {fetchUser} from '@/api/api.users';
import {fetchOrder} from '@/api/api.orders';
import PfSelectRestful from '@/components/Input/PfSelectRestful.vue';
import PfOptionSelect from '@/components/Input/PfOptionSelect.vue';
import {fetchEmployer} from '@/api/api.employers';
import PfInputDate from "@/components/Input/PfInputDate";
import PfInputTextArea from "@/components/Input/PfInputTextArea";
import PfInputNumber from "@/components/Input/PfInputNumber";
import useEmitter from '@/hooks/useEmitter';
import MatchHistory from "@/components/Input/MatchHistory";
import mapItemsToOptions from "@/utils/mapItemsToOptions";
import {getInvoicePreviewUrl, sendInvoice} from "@/api/api.invoices";

export default {
  name: 'CreateMatch',
  props: {
    queryParent: {
      type: String,
      default: null,
    },
  },
  components: {PfInputNumber, PfInputText, PfSelectRestful, PfOptionSelect, PfInputDate, PfInputTextArea, MatchHistory},
  setup(props) {
    const formData = reactive({
      matchState: 'pending',
      matchType: 'invitation',
      jobId: null,
      employerId: null,
      userId: null,
      initialApplication: false,
      sharePermission: 'disabled',
      shareUrl: null,
      comment: '',
      customerComment: '',
      workStartDate: null,
      salaryByCandidate: null,
      salaryByCustomer: null,
      interviewDate: null,
      sendMailToCustomer: false,
      paidDate: null,
      billedDate: null,
      history: [],
      fee: null,
      feeId: null,
      invoice: null,
      invoiceId: null,
    });
    const feesOptions = ref([]);
    const existingMatchId = ref();
    const userUrl = ref();
    const jobUrl = ref();
    const commentOnUserEntity = ref();
    const initialData = reactive({
      job: null,
      employer: null,
      user: null,
    });
    watch(
        () => formData.initialApplication,
        (currVal, prevVal) => {
          if (currVal && !prevVal) {
            formData.jobId = null;
            initialData.job = null;
          }
        }
    );
    watch(
        () => formData.jobId,
        async (currVal, prevVal) => {
          if (currVal !== prevVal) {
            if (currVal) {
              const {employer} = await fetchOrder(currVal);
              initialData.employer = employer;
              formData.employerId = employer.id;
            }
          }
        }
    );

    const {removePortionByKey} = useQueryUpdater();
    const toast = useToast();
    const store = useStore();

    onMounted(async () => {
      const entireQuery = store.state.router.query;
      const relevantUrlQuery = props.queryParent
          ? entireQuery[props.queryParent]
          : entireQuery;

      if (relevantUrlQuery) {
        const {matchId, jobId, employerId, userId} = relevantUrlQuery;
        if (matchId) {
          const match = await fetchMatch(matchId);
          userUrl.value = `${window.location.protocol}//${window.location.host}/users/${match.userId}`;
          jobUrl.value = `${window.location.protocol}//${window.location.host}/jobs/${match.jobId}`;
          commentOnUserEntity.value = match.user.comment;
          const {
            initialApplication,
            comment,
            customerComment,
            matchType,
            matchState,
            sharePermission,
            user,
            job,
            employer,
            shareToken,
            workStartDate,
            salaryByCandidate,
            salaryByCustomer,
            interviewDate,
            paidDate,
            billedDate,
            history,
            fee,
            invoice,
          } = match;
          existingMatchId.value = matchId;
          Object.assign(formData, {
            initialApplication,
            comment,
            customerComment,
            matchType,
            matchState,
            sharePermission,
            workStartDate,
            salaryByCandidate,
            salaryByCustomer,
            interviewDate,
            paidDate,
            billedDate,
            history,
            fee,
            feeId: fee?.id,
            invoiceId: invoice?.id,
            shareUrl: `${window.location.protocol}//${window.location.host}/share/match/${shareToken}`,
            jobId: job?.id,
            employerId: employer.id,
            userId: user.id,
          });
          if (user) {
            initialData.user = user;
          }
          if (job) {
            initialData.job = job;
          }
          if (employer) {
            initialData.employer = employer;
            feesOptions.value = employer?.fees?.map((fee) =>
                mapItemsToOptions.fees(fee)
            );
          }
        }
        if (jobId) {
          const job = await fetchOrder(jobId);
          initialData.job = job;
          formData.jobId = job.id;
        }
        if (employerId) {
          const employer = await fetchEmployer(employerId);
          initialData.employer = employer;
          formData.employerId = employer.id;
        }
        if (userId) {
          const user = await fetchUser(userId);
          initialData.user = user;
          formData.userId = user.id;
        }
      }
    });
    const emitter = useEmitter()
    const handleSubmit = async () => {
      try {
        const {
          matchState,
          matchType,
          sharePermission,
          jobId,
          employerId,
          userId,
          comment,
          customerComment,
          sendMailToCustomer,
          initialApplication,
          workStartDate,
          salaryByCandidate,
          salaryByCustomer,
          interviewDate,
          paidDate,
          billedDate,
          feeId,
        } = formData;
        const payload = {
          matchState,
          matchType,
          sharePermission,
          jobId,
          employerId,
          userId,
          comment,
          customerComment,
          sendMailToCustomer,
          initialApplication,
          workStartDate,
          salaryByCandidate,
          salaryByCustomer,
          interviewDate,
          paidDate,
          billedDate,
          feeId
        };
        if (existingMatchId.value) {
          await updateMatch(existingMatchId.value, payload);
          emitter.emit('save-match-event')
        } else {
          await createMatch(payload);
        }
        store.dispatch('matches/fetchItems');
        toast.success('Good job!');
        removePortionByKey('modal');
      } catch (error) {
        console.log(error)
        toast.error('Something went terribly wrong. Check console for details');
      }
    };

    const openInvoicePreviewUrl = async () => {
      try {
      const url = await getInvoicePreviewUrl(formData.invoiceId);
      window.open(url, '_blank').focus();
    } catch (error) {
      console.log(error)
      toast.error('Error. Check console for details');
    }
    };

    const sendInvoiceToCustomer = async () => {
      try {
        await sendInvoice(formData.invoiceId);
        toast.success('Rechnung versendet!');
      } catch (error) {
        console.log(error)
        toast.error('Rechnung versendet fehlgeschlagen. Check console for details');
      }
    };

    return {
      ...toRefs(formData),
      matchStatesUpdate: orderStates,
      feesOptions,
      sharePermissions,
      handleSubmit,
      openInvoicePreviewUrl,
      sendInvoiceToCustomer,
      existingMatchId,
      userUrl,
      jobUrl,
      commentOnUserEntity,
      ...toRefs(initialData),
    };
  },
};
</script>
