<template>
  <pv-button icon="pi pi-cog" class="p-button-rounded p-button-text p-button-plain" aria-label="Submit" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" />
  <pv-menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'PfSettings',
  data() {
    return {
      items: [
        {
          label: 'Settings',
          items: [
            /*{
              label: 'Invoices',
              icon: 'pi pi-euro',
              to: '/invoices'
            },*/
            {
              label: 'Users',
              icon: 'pi pi-fw pi-user',
              to: '/users',
            },
            {
              label: 'Logout',
              icon: 'pi pi-power-off',
              command: () => {
                this.logout();
              }
            },
          ]
        },
      ]
    };
  },
  methods: {
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    ...mapActions('auth', ['logout']),
  }
};
</script>
<style>

.router-link-active span{
  font-weight: bold;
  color: var(--primary-color) !important;
}
</style>