<template>
  <pv-dialog
    :header="modalTitle"
    v-model:visible="displayModal"
    :style="{ width: '50vw' }"
    :modal="true"
    footerStyle="p-0"
  >
    <div>
      <component :is="component" :queryParent="'modal'" />
    </div>
  </pv-dialog>
</template>
<script>
import { computed } from '@vue/runtime-core';
import { useStore } from 'vuex';
import Dialog from 'primevue/dialog';
import useQueryUpdater from '@/hooks/useQueryUpdater';
import CreateMatch from '@/forms/CreateMatch';
import CreateTask from "@/forms/CreateTask";
const modals = {
  'create-match': CreateMatch,
  'edit-match': CreateMatch,
  'create-task': CreateTask,
  'edit-task': CreateTask,
};
const modalTitles = {
  'create-match': 'Create match',
  'edit-match': 'Edit match',
  'create-task': 'Create task',
  'edit-task': 'Edit task',
};
export default {
  name: 'PfRootModal',
  components: {
    'pv-dialog': Dialog,
    CreateMatch, CreateTask
  },
  setup() {
    const store = useStore();
    const modalQuery = computed(() => store.state.router.query.modal);
    const { removePortionByKey } = useQueryUpdater();
    const displayModal = computed({
      get() {
        return !!modalQuery.value;
      },
      set(e) {
        if (!e) {
          removePortionByKey('modal');
        }
      },
    });
    const component = computed(() => modals[modalQuery.value?.form]);
    const modalTitle = computed(() => modalTitles[modalQuery.value?.form]);
    return {
      displayModal,
      component,
      modalTitle,
      modalQuery,
    };
  },
};
</script>
