import mapItemsToOptions from '@/utils/mapItemsToOptions';

const createFlatListStateGetters = (dataKey) => {
  return {
    allItems: (state) => state.items,
    itemsAsOptions: ({ items }) =>
      items.map((item) => mapItemsToOptions[dataKey](item)),
  };
};
const createPaginationActions = (fetchCb) => {
  return {
    async fetchItems(context) {
      const { data } = await fetchCb();
      context.commit('setItems', data);
    },
  };
};

const createFlatListStateMutations = () => {
  return {
    setItems: (state, payload) => {
      state.items = payload;
    },
  };
};

const createFlatListState = () => {
  return {
    items: [],
  };
};

const createFlatListStoreModule = ({ fetchCb, dataKey }) => {
  return {
    namespaced: true,
    actions: createPaginationActions(fetchCb),
    mutations: createFlatListStateMutations(),
    getters: createFlatListStateGetters(dataKey),
    state: createFlatListState(),
  };
};

export default createFlatListStoreModule;
