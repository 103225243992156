export const typedEntityDict = {
  workAreas: 'workAreaIds',
  categories: 'categoryIds',
  employmentTypes: 'employmentTypeIds',
  agents: 'agentIds',
  tags: 'tagIds',
  preferredEmployers: 'preferredEmployerIds',
  blacklistEmployers: 'blacklistEmployerIds',
  employer: 'employerId',
  employerGroup: 'employerGroupId',
  contactPersons: 'contactPersonIds',
  hrContactPerson: 'hrContactPersonId',
  billingContactPerson: 'billingContactPersonId',
  positions: 'positionIds',
  position: 'positionId',
  contactPerson: 'contactPersonId',
};

export const addableTypedEntities = [
  'educations',
  'furtherEducations',
  'experiences',
  'userLocations',
  'userFiles',
  'fees',
];

export const typedEntitiesList = Object.keys(typedEntityDict);
