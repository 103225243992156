<template>
  <div>
    <label :for="valueKey" class="text-sm">{{ label }}</label>
    <pv-calendar
        :modelValue="parsedModelValue"
        @date-select="handleDateSelect"
        :manualInput="true"
        date-format="dd.mm.yy"
        :disabled="disabled"
        :showTime="true"
        :id="valueKey || label.split(' ').join('')"
        class="w-full mt-2"
    />
  </div>
</template>
<script>
import {computed} from '@vue/runtime-core';
import dayjs from "dayjs";

export default {
  name: 'PfInputDate',
  props: {
    valueKey: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const parsedModelValue = computed(() =>
        props.modelValue ? new Date(props.modelValue) : null
    );
    return {
      parsedModelValue,
      handleDateSelect(date) {
        emit('update:modelValue', date.toISOString());
      },
      handleTypedDate(e) {
        const customParseFormat = require('dayjs/plugin/customParseFormat')
        dayjs.extend(customParseFormat)
        let parsedDate = dayjs(e.target.value.toString(), "DD.MM.YYYY");
        if (parsedDate.isValid()) {
          emit('update:modelValue', parsedDate.toDate().toISOString());
        }
        parsedDate = dayjs(e.target.value.toString(), "DDMMYYYY");
        if (parsedDate.isValid()) {
          emit('update:modelValue', parsedDate.toDate().toISOString());
        }
      }
    };
  },
};
</script>
