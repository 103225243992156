<template>
  <div>
    <label :for="valueKey" class="text-sm">{{ label }}</label>
    <pv-calendar
        :modelValue="parsedModelValue"
        @date-select="handleDateSelect"
        @input="handleTypedDate"
        :manualInput="true"
        date-format="HH.mm"
        :disabled="disabled"
        :showSeconds="false"
        :timeOnly="true"
        hourFormat="24"
        :id="valueKey || label.split(' ').join('')"
        class="w-full mt-2"
    />
  </div>
</template>
<script>
import {computed} from '@vue/runtime-core';
import dayjs from "dayjs";

export default {
  name: 'PfInputTime',
  props: {
    valueKey: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const parsedModelValue = computed(() =>
        props.modelValue ? new Date(props.modelValue) : null
    );
    return {
      parsedModelValue,
      handleDateSelect(date) {
        emit('update:modelValue', date.toISOString());
      },
      handleTypedDate(e) {
        const customParseFormat = require('dayjs/plugin/customParseFormat')
        dayjs.extend(customParseFormat)
        let parsedDate = dayjs(e.target.value.toString(), "hhmm");
        if (parsedDate.isValid() && e.target.value.toString().length === 4) {
          emit('update:modelValue', parsedDate.toDate().toISOString());
        }
      /*  parsedDate = dayjs(e.target.value.toString(), "hhmm");
        if (parsedDate.isValid()) {
          emit('update:modelValue', parsedDate.toDate().toISOString());
        }*/
      }
    };
  },
};
</script>
