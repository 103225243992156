import axios from 'axios';
import QueryString from 'qs';
import { API_BASE_URL, PRIVATE_API_ROUTES } from '../constants/api.constants';
import router from '@/router';
const apiClient = axios.create({
  baseURL: API_BASE_URL,
  paramsSerializer: (params) => {
    return QueryString.stringify(params, { arrayFormat: 'brackets' });
  },
});

export const TOKEN_KEY = 'PFELGINO_AUTH';

apiClient.interceptors.request.use((req) => {
  const isProtectedRoute = Object.values(PRIVATE_API_ROUTES).some((route) =>
    req.url.startsWith(route)
  );
  if (isProtectedRoute) {
    return {
      ...req,
      headers: {
        Authorization: `bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    };
  } else {
    return req;
  }
});

apiClient.interceptors.response.use(
  function (successRes) {
    return successRes;
  },
  function (error) {
    if (error.response.status === 401) {
      router.replace({ name: 'logout' });
    } else {
      return Promise.reject(error);
    }
  }
);

export default apiClient;
