<template>
  <div>
    <label :for="valueKey" class="text-sm">{{ label }}</label>
    <pv-input-text
      :modelValue="modelValue"
      :placeholder="placeholder"
      @input="handleInput"
      :disabled="disabled"
      :id="valueKey || label.split(' ').join('')"
      class="w-full mt-2"
    />
  </div>
</template>
<script>
export default {
  name: 'PfInputText',
  props: {
    valueKey: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    return {
      handleInput(event) {
        emit('update:modelValue', event.target.value);
      },
    };
  },
};
</script>
