import { PRIVATE_API_ROUTES } from '@/constants/api.constants';
import { getPaginatedRestModule } from './utils';

const route = PRIVATE_API_ROUTES.tasks;

const {
  fetchItem: fetchTask,
  fetchItems: fetchTasksPaginated,
  updateItem: updateTask,
  createItem: createTask,
  deleteItem: deleteTask,
} = getPaginatedRestModule({ route });

export {
  fetchTask,
  fetchTasksPaginated,
  updateTask,
  createTask,
  deleteTask,
};
