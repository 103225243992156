import { PRIVATE_API_ROUTES } from '@/constants/api.constants';
import { getPaginatedRestModule } from './utils';
import apiClient from "@/api/apiClient";

const route = PRIVATE_API_ROUTES.calls;

export const callReached = async (callId) => {
  const { data } = await apiClient.put(`${PRIVATE_API_ROUTES.calls}/${callId}`, { callState: 'reached' });
  return data;
};

export const callNotReached = async (callId) => {
  const { data } = await apiClient.put(`${PRIVATE_API_ROUTES.calls}/${callId}`, { callState: 'notReached' });
  return data;
};

export const callNotNecessary = async (callId) => {
  const { data } = await apiClient.put(`${PRIVATE_API_ROUTES.calls}/${callId}`, { callState: 'notNecessary' });
  return data;
};

const {
  fetchItem: fetchCall,
  fetchItems: fetchCallsPaginated,
  updateItem: updateCall,
  createItem: createCall,
  deleteItem: deleteCall,
} = getPaginatedRestModule({ route });

export {
  fetchCall,
  fetchCallsPaginated,
  updateCall,
  createCall,
  deleteCall,
};
