import { useToast } from 'vue-toastification';
import { addableTypedEntities, typedEntityDict } from './typedValues';
const toast = useToast();

const adjustAddableTypedEntities = (value) => {
  return value.map(({ isNew, id, ...rest }) =>
    isNew ? { ...rest } : { id, ...rest }
  );
};
const createEntityGetters = ({ customGetters }) => {
  return {
    // refers to raw, non-typed values
    // such as yearsOfExperience, salary,...
    getDraftOrRealValues:
      (state) =>
      (keys = []) => {
        const { entityUpdateDraft, entityData } = state;
        const res = keys.reduce((acc, key) => {
          return {
            ...acc,
            [key]: Object.keys(entityUpdateDraft).includes(key)
              ? entityUpdateDraft[key]
              : entityData[key],
          };
        }, {});
        return res;
      },
    // refers to values which are in other tables
    // and are returned as objects with IDs and other meta info
    // i.e. workAreas, employmentTypes...
    getDraftOrRealTypedValues:
      (state) =>
      (keys = []) => {
        const { entityUpdateDraft, entityData } = state;
        const res = keys.reduce((acc, key) => {
          try {
            return {
              ...acc,
              [key]: Object.keys(entityUpdateDraft).includes(key)
                ? entityUpdateDraft[key]
                : Array.isArray(entityData[key])
                ? entityData[key].map(({ id }) => id)
                : entityData[key]?.id,
            };
          } catch (error) {
            console.error('error', error);
          }
        }, {});
        return res;
      },
    getIsDraftDirty: (state) => Object.keys(state.entityUpdateDraft).length,
    getFormValues: (state) => {
      const { entityUpdateDraft, entityData } = state;
      const allKeys = [
        ...new Set([
          ...Object.keys(entityUpdateDraft),
          ...Object.keys(entityData),
        ]),
      ];
      const res = allKeys.reduce((acc, key) => {
        const valForKey = Object.keys(entityUpdateDraft).includes(key)
          ? entityUpdateDraft[key]
          : entityData[key];
        const isTypedValue = Object.keys(typedEntityDict).includes(key);

        const processedValForKey = !isTypedValue
          ? valForKey
          : !Array.isArray(valForKey)
          ? valForKey.id
          : valForKey.map(({ id }) => id);

        return {
          ...acc,
          [key]: processedValForKey,
        };
      }, {});
      return res;
    },
    getPatchedDraftForUpdateCall: (state) => {
      const patchedDraft = {};

      for (const [key, value] of Object.entries(state.entityUpdateDraft)) {
        if (key in typedEntityDict) {
          const dictEntry = typedEntityDict[key];
          patchedDraft[dictEntry] = value;
        } else if (addableTypedEntities.includes(key)) {
          patchedDraft[key] = adjustAddableTypedEntities(value);
        } else {
          patchedDraft[key] = value;
        }
      }

      return patchedDraft;
    },
    ...customGetters,
  };
};
const createEntityActions = (fetchCb, deleteCb, updateCb, createCb) => {
  return {
    async fetchEntity(context, entityId) {
      context.commit('setLoading', true);
      const data = await fetchCb(entityId);
      context.commit('setLoading', false);
      context.commit('setEntity', data);
    },
    async saveEntityUpdateDraft(context) {
      try {
        const { id } = context.state.entityData;
        const { data } = id
          ? await updateCb(id, context.getters.getPatchedDraftForUpdateCall)
          : await createCb(context.getters.getPatchedDraftForUpdateCall);
        await context.dispatch('fetchEntity', data.id);
        context.commit('clearEntityUpdateDraft');
      } catch (error) {
        toast.error('Could not save user. Check console for more info.');
        console.error(error);
        throw error;
      }
    },
    async deleteItem(context, target) {
      const currentItems = context.state.items;
      try {
        context.commit(
          'setItems',
          currentItems.filter((item) => item.id !== target.id)
        );
        await deleteCb(target.id);
      } catch (error) {
        toast.error('Something went wrong, check console for details.');
        console.error(error);
        context.commit('setItems', currentItems);
      }
    },
    async updateItem(context, { id, data }) {
      try {
        await updateCb(id, data);
        context.dispatch('fetchItems');
      } catch (error) {
        toast.error('Something went wrong, check console for details.');
        console.error(error);
      }
    },
  };
};

const createEntityMutations = () => {
  return {
    setEntity: (state, payload) => {
      state.entityData = payload;
    },
    setEntityUpdateDraft: (state, payload) => {
      state.entityUpdateDraft[payload.updateKey] = payload.updateValue;
    },
    clearEntityUpdateDraft: (state) => {
      state.entityUpdateDraft = {};
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    resetEntity: (state) => {
      Object.assign(state, createEntityState());
    },
  };
};

const createEntityState = () => {
  return {
    entityData: {},
    entityUpdateDraft: {},
    loading: false,
  };
};

const createEntityStoreModule = ({
  fetchCb,
  deleteCb,
  updateCb,
  createCb,
  customGetters = {},
}) => {
  return {
    namespaced: true,
    actions: createEntityActions(fetchCb, deleteCb, updateCb, createCb),
    mutations: createEntityMutations(),
    getters: createEntityGetters({ customGetters }),
    state: createEntityState(),
  };
};

export default createEntityStoreModule;
