import { PRIVATE_API_ROUTES } from '@/constants/api.constants';
import { getPaginatedRestModule } from './utils';

const route = PRIVATE_API_ROUTES.jobBoosts;

const {
  fetchItem: fetchJobBoost,
  fetchItems: fetchJobBoostsPaginated,
  updateItem: updateJobBoost,
  createItem: createJobBoost,
  deleteItem: deleteJobBoost,
} = getPaginatedRestModule({ route });

export {
  fetchJobBoost,
  fetchJobBoostsPaginated,
  updateJobBoost,
  createJobBoost,
  deleteJobBoost,
};
