import { PRIVATE_API_ROUTES } from '@/constants/api.constants';
import { getPaginatedRestModule } from './utils';

const route = PRIVATE_API_ROUTES.pages;
const {
  fetchItem: fetchPage,
  fetchItems: fetchPagesPaginated,
  updateItem: updatePage,
  createItem: createPage,
  deleteItem: deletePage,
} = getPaginatedRestModule({ route });

export { fetchPage, fetchPagesPaginated, updatePage, createPage, deletePage };
