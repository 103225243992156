import { TOKEN_KEY } from '@/api/apiClient';
import { apiLogin } from '@/api/api.auth';
import router from '@/router';
import { useToast } from 'vue-toastification';

const Toast = useToast();

export default {
  auth: {
    namespaced: true,
    state: {
      token: localStorage.getItem(TOKEN_KEY) || null,
    },
    mutations: {
      setAuthToken(state, payload) {
        state.token = payload;
      },
    },
    actions: {
      async login(context, { username, password }) {
        try {
          const token = await apiLogin(username, password);
          context.commit('setAuthToken', token);
          localStorage.setItem(TOKEN_KEY, token);
          router.replace({ name: 'home' });
        } catch (error) {
          Toast.error('Failed to log in', { timeout: 3000 });
        }
      },
      logout(context) {
        context.commit('setAuthToken', null);
        localStorage.clear(TOKEN_KEY);
        router.replace({ name: 'auth' });
      },
    },
  },
};
