<template>
  <div>
    <label :for="valueKey" class="text-sm">{{ label }}</label>
    <pv-text-area :autoResize="autoResize" :rows="rows" class="w-full mt-2"
                  :modelValue="modelValue"
                  :placeholder="placeholder"
                  @input="handleInput"
                  :id="valueKey || label.split(' ').join('')"
    />
  </div>
</template>
<script>
export default {
  name: 'PfInputTextArea',
  props: {
    valueKey: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
    rows: {
      type: Number,
      default: 5,
    },
  },
  setup(props, {emit}) {
    return {
      handleInput(event) {
        emit('update:modelValue', event.target.value);
      },
    };
  },
};
</script>
